import React from "react";

const SmallCommentIcon = () => {
  return (
    <svg
      width="11px"
      height="11px"
      viewBox="0 0 11 11"
      className="icon_reaction--25RS_"
    >
      <path
        fill="#444"
        d="M5.5 0C8.53756612 0 11 2.46243388 11 5.5S8.53756612 11 5.5 11c-.91695777 0-1.80211463-.22556492-2.59047101-.64720523L2.875 10.333l-.19709054.06589233-1.22117747.41942606c-.79715492.27906151-1.55986506-.49464483-1.2694221-1.28772376l.33041123-.92310743L.677 8.143l-.01974018-.03381012c-.38524837-.71365255-.60943013-1.50748003-.650414-2.33293386L0 5.5C0 2.46243388 2.46243388 0 5.5 0zm0 1C3.01471863 1 1 3.01471863 1 5.5c0 .93082954.28262045 1.79564953.76673257 2.51333121-.06138403.22053313-.20681608.64566345-.34359844 1.03399111l-.29681304.82715888 1.47271605-.50290832.40498209-.12666013C3.71827023 9.72190879 4.57665927 10 5.5 10 7.98528137 10 10 7.98528137 10 5.5S7.98528137 1 5.5 1zm1.85606063 4c.31379816 0 .56818184.25743628.56818184.57500002 0 .31756375-.25438368.57500003-.56818184.57500003-.31379817 0-.56818184-.25743628-.56818184-.57500003C6.78787879 5.25743628 7.04226246 5 7.35606063 5zm-1.8939394 0c.31379817 0 .56818185.25743628.56818185.57500002 0 .31756375-.25438368.57500003-.56818185.57500003-.31379816 0-.56818184-.25743628-.56818184-.57500003C4.89393939 5.25743628 5.14832307 5 5.46212123 5zM3.56818184 5c.31379817 0 .56818184.25743628.56818184.57500002 0 .31756375-.25438367.57500003-.56818184.57500003C3.25438367 6.15000005 3 5.89256377 3 5.57500002 3 5.25743628 3.25438367 5 3.56818184 5z"
      ></path>
    </svg>
  );
};

export default SmallCommentIcon;
