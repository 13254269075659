// Loading.js
import React from 'react';
import '../pages/styles.css'; // CSS 파일을 import

const Loading = () => (
  <div className="loader">
    <span></span>
    <span></span>
    <span></span>
  </div>
);

export default Loading;
