import { BrowserRouter } from "react-router-dom";
import React from 'react';
import NavigationButtons from './components/NavigationButtons'; // 경로에 맞게 수정

import Routes from "./Routes";

const App = (props) => {
  return (
    <div>
      {/* 나머지 앱 내용 */}
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <NavigationButtons />
      <div className="app-container">
      <div className="footer">
        <a href="https://discord.gg/duckko" target="_blank" rel="discord link" style={{ color: 'white' }}>
          덕질코리아 디스코드에 들어오세요!
        </a>
      </div>
    </div>
    </div>
    
  );
};

export default App;