import React from "react";

const SmallPlayIcon = () => {
  return (
    <svg
      width="9"
      height="11"
      viewBox="0 0 9 11"
      className="icon_reaction--25RS_"
    >
      <path
        fill="#444"
        d="M.9 9.95v-8.9L7.972 5.5.9 9.95zm7.745-5.104L1.12.11C1.062.074 1 .047.937.028.915.022.892.022.87.018.828.01.786 0 .744 0 .7 0 .658.008.616.016c-.02.003-.04.002-.06.008C.493.04.432.064.375.098.318.132.266.173.219.22.081.363 0 .558 0 .765v9.47c0 .207.081.402.22.544.045.047.098.089.156.122.056.033.115.058.176.074.06.017.123.025.186.025.132 0 .265-.037.382-.11l7.525-4.736C8.865 6.015 9 5.767 9 5.5c0-.268-.135-.516-.355-.654z"
      ></path>
    </svg>
  );
};

export default SmallPlayIcon;
