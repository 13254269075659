import React from 'react';
import '../pages/styles.css'; // CSS 파일 import

const NavigationButtons = () => {
  const goBack = () => {
    window.history.back();
  };

  const goHome = () => {
    window.location.href = '/'; // 홈 페이지 URL로 변경
  };

  return (
    <div className="navigation-buttons">
      <button onClick={goBack} aria-label="뒤로가기">
        <i className="fas fa-arrow-left"></i> {/* 뒤로가기 아이콘 */}
      </button>
      <button onClick={goHome} aria-label="홈">
        <i className="fas fa-home"></i> {/* 홈 아이콘 */}
      </button>
    </div>
  );
};

export default NavigationButtons;
