import React, { useState, useEffect } from "react";
import "../../pages/styles.css";
import ArtistCard from "./artistcard.js";
import axios from "axios";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Loading from '../../components/Loading'; // Loading 컴포넌트 import
import InfiniteScroll from "react-infinite-scroll-component";

const Landing = () => {
  //const { t } = useTranslation();
  document.title = "덕질코리아 브이라이브 아카이브";

  const [channelList, setChannelList] = React.useState([]);

  const [renderNum, setRenderNum] = React.useState(20);
  const [hasMore, setHasMore] = useState(true);

  const fetchNextData = () => {
    setRenderNum(renderNum + 20);
  };

  useEffect(() => {
    axios
      .get(`https://api.vlivearchive.com/channels?page=${renderNum / 20}`)
      .then(function (response) {
        setChannelList((channelList) => [...channelList, ...response.data]);
      })
      .catch(function (error) {});
  }, [renderNum]);

  if (channelList.length === 0) {
    return <Loading />; // 로딩 애니메이션 사용
  }

  const handleSearch = (value) => {
    setHasMore(value.length === 0);

    if (value.length === 1) return;
    axios
      .get(`https://api.vlivearchive.com/channels/search?q=${value}`)
      .then(function (response) {
        setChannelList(response.data);
      })
      .catch(function (error) {});
  };

  return (
    <>
      <div className="GlobalLayoutView_layout_container__kUnkV GlobalLayoutView_-min_width_745__06yCp">
        <div className="body">
          <div className="HomePageView_container__Gk7q3">
            <div className="HomePageView_home_main__JwIMd">
              <div>
                <div className="HomeComponentView_component_area__nBJJk">
                  <strong className="HomeComponentView_title__wTRwM">
                    덕질코리아 브이앱 아카이브
                  </strong>
                  <TextField
                    label="Search"
                    sx={{ mt: 2 }}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  <div className="HomeComponentView_component__oey5Q">
                    <ul>
                      <InfiniteScroll
                        className="HomeArtistListSlotView_artist_list__3fPzz"
                        dataLength={renderNum}
                        next={fetchNextData}
                        hasMore={hasMore}
                        loader={<h4>덕질코리아 브이라이브 아카이브</h4>}
                      >
                        {channelList.map((channel, key) => (
                          <ArtistCard channel={channel} key={key} />
                        ))}
                      </InfiniteScroll>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
