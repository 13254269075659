import React from "react";

const PostStarIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12">
      <g fill="none">
        <circle cx="6" cy="6" r="6" fill="#8D54E6"></circle>
        <path
          fill="#FFF"
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.2"
          d="M8.464 4.81c.122 0 .237.055.313.15.137.174.108.425-.065.563h0l-1.223.97.396 1.39c.033.118.011.244-.06.343-.13.18-.38.22-.559.091h0L6 7.406l-1.267.911c-.18.13-.43.089-.558-.09-.072-.1-.094-.226-.06-.344l.395-1.39-1.223-.97c-.173-.138-.202-.39-.064-.562.075-.096.19-.152.313-.152H5.13l.49-1.396c.01-.025.02-.05.034-.072l-.033.072c.01-.03.025-.06.042-.085.01-.017.023-.033.036-.048l.014-.015c.012-.012.025-.024.039-.035l.026-.018c.027-.018.056-.033.087-.044l-.054.024.037-.017.017-.007.013-.004c.015-.005.031-.009.047-.012l.032-.005L6 3.145l.04.002.034.005c.016.003.032.007.047.012l.012.004.016.006c.014.006.028.012.041.02l-.057-.026c.031.011.061.026.088.044l.023.016c.015.012.03.025.043.039l.012.013c.011.012.021.025.03.038.02.028.036.06.048.095h0l.492 1.396z"
        ></path>
      </g>
    </svg>
  );
};

export default PostStarIcon;
